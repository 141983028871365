import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppRoutesEnum } from '../../routes/app-routes.enum';
import { AuthenticationDataService } from 'outshared-lib';
import { filter, map, switchMap } from 'rxjs/operators';
import { SalesfunnelRoutes } from '../../salesfunnel/routing/salesfunnel-routing.routes';
import { SalesfunnelService } from '../../salesfunnel/salesfunnel.service';
//eslint-disable-next-line @nx/enforce-module-boundaries
import { HemaActivationRoutesEnum } from '@inshared/authentication/feature-hema-activation';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationRedirectService {

    private redirectUrl: string;

    private blacklistedUrls: string[] = [
        `/${AppRoutesEnum.Login}`,
        `/${AppRoutesEnum.Logout}`,
        `/${AppRoutesEnum.Activate}`,
        `/${AppRoutesEnum.HemaActivation}/${HemaActivationRoutesEnum.TokenProcessing}`,
    ]

    constructor(private authenticationDataService: AuthenticationDataService,
                private salesfunnelService: SalesfunnelService,
                private router: Router) {
    }

    public init(): void {
        this.authenticationDataService.initializationPending$().pipe(
            filter((pending: boolean) => !pending),
            switchMap(() => this.authenticationDataService.sessionExpired$().pipe(
                map((expired: boolean) => {
                    return expired && !this.isUrlBlacklisted(this.router.url)
                        ? this.router.navigateByUrl(this.redirectThroughLogin(this.router.url)) : null;
                }),
            ))).subscribe();
    }

    public redirectThroughLogin(url?: string): UrlTree {
        this.redirectUrl = url;

        // Redirect to salesfunnel login page when session is expired or logout
        const fragments = url.split('/');
        const lastFragment = fragments[fragments.length - 1];
        if (this.salesfunnelService.isSalesfunnel(url) &&
            (lastFragment === SalesfunnelRoutes.Summary ||
                lastFragment === SalesfunnelRoutes.PersonalData)) {
            fragments[fragments.length - 1] = SalesfunnelRoutes.PersonalData;
            return this.router.createUrlTree([fragments.join('/')]);
        }

        return this.router.createUrlTree(['/', AppRoutesEnum.Login]);
    }

    public redirectOrPassThrough(): boolean | UrlTree {
        if (this.redirectUrl) {
            const url = this.redirectUrl;
            this.redirectUrl = null;
            return this.router.parseUrl(url);
        }
        return true;
    }

    private isUrlBlacklisted(url: string): boolean {
        const urlTree = this.router.parseUrl(url);
        urlTree.queryParams = {};
        urlTree.fragment = null;

        if (this.salesfunnelService.isSalesfunnel(url)) {
            // Blacklisting the login page in the salesfunnels
            const fragments = url.split('/');
            fragments[fragments.length - 1] = SalesfunnelRoutes.PersonalData;
            return fragments.join('/') === url;
        }

        return this.blacklistedUrls.includes(urlTree.toString());
    }
}
